define("discourse/plugins/discourse-view-counts/discourse/initializers/view-counts-admin", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "view-counts-admin",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.31", () => {
        // Keep any other necessary initialization logic here
      });
    }
  };
});