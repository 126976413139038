define("discourse/plugins/discourse-view-counts/discourse/components/admin-view-counts", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error", "I18n"], function (_exports, _component, _tracking, _object, _ajax, _ajaxError, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminViewCounts extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "topicId", [_tracking.tracked], function () {
      return "";
    }))();
    #topicId = (() => (dt7948.i(this, "topicId"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "viewCount", [_tracking.tracked], function () {
      return 0;
    }))();
    #viewCount = (() => (dt7948.i(this, "viewCount"), void 0))();
    updateTopicId(event) {
      this.topicId = event.target.value;
    }
    static #_3 = (() => dt7948.n(this.prototype, "updateTopicId", [_object.action]))();
    updateViewCount(event) {
      this.viewCount = event.target.value;
    }
    static #_4 = (() => dt7948.n(this.prototype, "updateViewCount", [_object.action]))();
    async submitViewCount() {
      try {
        await (0, _ajax.ajax)(`/discourse-view-counts/view_count/${this.topicId}`, {
          type: "PUT",
          data: {
            view_count: this.viewCount
          }
        });
        this.flashMessages.success(_I18n.default.t("discourse_view_counts.admin.success"));
      } catch (error) {
        (0, _ajaxError.popupAjaxError)(error);
      }
    }
    static #_5 = (() => dt7948.n(this.prototype, "submitViewCount", [_object.action]))();
  }
  _exports.default = AdminViewCounts;
});