define("discourse/plugins/discourse-view-counts/discourse/templates/admin-view-counts", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="view-counts-admin">
    <h2>{{i18n "discourse_view_counts.admin.title"}}</h2>
  
    <div class="control-group">
      <label>{{i18n "discourse_view_counts.admin.topic_id"}}</label>
      <input type="number" value={{this.topicId}} {{on "input" this.updateTopicId}} />
    </div>
  
    <div class="control-group">
      <label>{{i18n "discourse_view_counts.admin.view_count"}}</label>
      <input type="number" min="0" value={{this.viewCount}} {{on "input" this.updateViewCount}} />
    </div>
  
    <DButton
      @action={{this.submitViewCount}}
      @label="discourse_view_counts.admin.update"
      @class="btn-primary"
    />
  </div>
  */
  {
    "id": "GcaHDkY9",
    "block": "[[[10,0],[14,0,\"view-counts-admin\"],[12],[1,\"\\n  \"],[10,\"h2\"],[12],[1,[28,[35,2],[\"discourse_view_counts.admin.title\"],null]],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n    \"],[10,\"label\"],[12],[1,[28,[35,2],[\"discourse_view_counts.admin.topic_id\"],null]],[13],[1,\"\\n    \"],[11,\"input\"],[16,2,[30,0,[\"topicId\"]]],[24,4,\"number\"],[4,[38,5],[\"input\",[30,0,[\"updateTopicId\"]]],null],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n    \"],[10,\"label\"],[12],[1,[28,[35,2],[\"discourse_view_counts.admin.view_count\"],null]],[13],[1,\"\\n    \"],[11,\"input\"],[24,\"min\",\"0\"],[16,2,[30,0,[\"viewCount\"]]],[24,4,\"number\"],[4,[38,5],[\"input\",[30,0,[\"updateViewCount\"]]],null],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[8,[39,6],null,[[\"@action\",\"@label\",\"@class\"],[[30,0,[\"submitViewCount\"]],\"discourse_view_counts.admin.update\",\"btn-primary\"]],null],[1,\"\\n\"],[13]],[],false,[\"div\",\"h2\",\"i18n\",\"label\",\"input\",\"on\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-view-counts/discourse/templates/admin-view-counts.hbs",
    "isStrictMode": false
  });
});